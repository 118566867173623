import { CriteriaSet } from './criteria-set.model';
import { SurveyCriteriaSetResponse } from './survey-criteriaset-response.model';
import { ItemSurvey } from './item-survey.interface';
import { Criteria } from './criteria.model';
import { FeedbackComment } from '../feedback/feedback-comment.model';
import { CommentHolder } from '../feedback/comment-holder.model';
import { CriteriaSetCriteria } from './criteriaset-criteria.model';
import { isNotNull, isNull } from 'src/app/utils/general.utils';

export class SurveyCriteriaSet implements ItemSurvey, CommentHolder {
  public id: number;
  public criteriaSetOrder: number;
  public criteriaSetId: number;
  public surveyTemplateCriteriaSetId: number;
  public surveyId: number;
  public responses: SurveyCriteriaSetResponse[];
  public comments: FeedbackComment[];
  private _criteriaSet: CriteriaSet;

  get criteriaSet(): CriteriaSet {
    return this._criteriaSet;
  }

  set criteriaSet(criteriaSet: CriteriaSet) {
    this._criteriaSet = criteriaSet;
    if (criteriaSet && criteriaSet.id) {
      this.criteriaSetId = criteriaSet.id;
    }
  }

  getCriterias(): CriteriaSetCriteria[] {
    if (this.criteriaSet != null) {
      return this.criteriaSet.criterias.sort((criteriaA, criteriaB) => {
        if (criteriaA.criteriaOrder < criteriaB.criteriaOrder) {
          return -1;
        } else if (criteriaA.criteriaOrder > criteriaB.criteriaOrder) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return [];
  }

  getCriteriasWithResponse(): Criteria[] {
    if (this.criteriaSet != null) {
      const result = this.criteriaSet.criterias
        .sort((criteriaA, criteriaB) => {
          if (criteriaA.criteriaOrder < criteriaB.criteriaOrder) {
            return -1;
          } else if (criteriaA.criteriaOrder > criteriaB.criteriaOrder) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((criteriaSetCriteria) => criteriaSetCriteria.criteria);

      result.forEach((resultItem) => {
        if (resultItem != null) {
          resultItem.response = resultItem.convertValueDisplay(
            this.responses.filter((response) => response.criteriaId == resultItem.id),
          );
        }
      });

      return result;
    }
    return [];
  }

  setResponse(value: number, criteriaId: number) {
    const responseIndex = this.responses.map((response) => response.criteriaId).indexOf(criteriaId);

    if (responseIndex > -1) {
      this.responses[responseIndex].value = value;
    } else {
      const surveyResponse: SurveyCriteriaSetResponse = {
        id: this.id,
        criteriaId,
        value,
        criteriaValueId: null,
        surveyCriteriaSetId: null,
      };
      this.responses.push(surveyResponse);
    }
  }

  setResponseCriteriaValue(criteriaValueId: number, criteriaId: number) {
    const responseIndex = this.responses.map((response) => response.criteriaId).indexOf(criteriaId);

    if (responseIndex > -1) {
      this.responses[responseIndex].criteriaValueId = criteriaValueId;
    } else {
      const surveyResponse: SurveyCriteriaSetResponse = {
        id: this.id,
        criteriaId,
        criteriaValueId,
        surveyCriteriaSetId: null,
        value: null,
      };
      this.responses.push(surveyResponse);
    }
  }

  setResponseCriteriaValues(criteriaValueIds: number[], criteriaId: number) {
    const responsesCriteria = this.responses.filter((response) => response.criteriaId == criteriaId);

    if (criteriaValueIds) {
      // On vide les réponses décochées
      responsesCriteria
        .filter((response) => criteriaValueIds.indexOf(response.criteriaValueId ? response.criteriaValueId : -1) == -1)
        .forEach((responseUnchecked) => (responseUnchecked.criteriaValueId = null));

      // On ajout les réponses cochées si elles ne sont pas déjà présentes
      criteriaValueIds.forEach((criteriaValueId) => {
        // criteriaValueId not found in responsesCriteria
        if (responsesCriteria.map((response) => response.criteriaValueId).indexOf(criteriaValueId) == -1) {
          // Getting the empty response - equals null
          const responsesEmpty = responsesCriteria.filter((response) => isNull(response.criteriaValueId));
          if (isNotNull(responsesEmpty) && responsesEmpty.length > 0) {
            responsesEmpty[0].criteriaValueId = criteriaValueId;
          } else {
            const surveyResponse: SurveyCriteriaSetResponse = {
              id: this.id,
              criteriaId,
              criteriaValueId,
              surveyCriteriaSetId: null,
              value: null,
            };
            responsesCriteria.push(surveyResponse);
          }
        }
      });
    } else {
      responsesCriteria.forEach((response) => (response.criteriaValueId = null));
    }
  }

  getItemId(): number | null {
    return this.criteriaSetId;
  }

  getResponses(): SurveyCriteriaSetResponse[] {
    return this.responses;
  }

  addComment(feedbackComment: FeedbackComment) {
    this.comments.push(feedbackComment);
  }

  getComments(): FeedbackComment[] {
    return this.comments;
  }

  getSurveyId(): number {
    return this.surveyId;
  }

  getHolderId(): number {
    return this.surveyId;
  }
}

export const mapSurveyCriteriaSets = (surveyCriteriaSets: SurveyCriteriaSet[]): SurveyCriteriaSet[] => {
  return surveyCriteriaSets.map((scs) => {
    const surveyCriteriaSet = Object.assign(new SurveyCriteriaSet(), scs);
    surveyCriteriaSet.criteriaSet = Object.assign(new CriteriaSet(), surveyCriteriaSet.criteriaSet);
    surveyCriteriaSet.criteriaSet.criterias = surveyCriteriaSet.criteriaSet.criterias.map((criteria) =>
      Object.assign(new CriteriaSetCriteria(), criteria),
    );
    return surveyCriteriaSet;
  });
};
