import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';
import { Category } from 'src/app/models/events/category.model';
import { EventGuestInformation } from 'src/app/models/events/event-guest-information.model';
import { Event } from 'src/app/models/events/event.model';
import { EventUserRanking } from 'src/app/models/events/ranking/event-user-ranking.model';
import { Survey } from 'src/app/models/surveys/survey.model';
import { EventSurveys } from '../survey/survey-load.service';
import { mapSurveyCriteriaSets } from '../../models/surveys/survey-criteriaset.model';
import { StorageService } from '../storage/storage.service';
import { LOCAL_CHANGE } from '../../utils/general.utils';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends Dexie {
  events: Table<Event, number>;
  eventSurveys: Table<EventSurveys, number>;
  categories: Table<Category, number>;
  eventUserRankings: Table<EventUserRanking, number>;
  eventGuestInformations: Table<EventGuestInformation, number>;

  constructor(private storageService: StorageService) {
    super('s2a-testing-dexie', { allowEmptyDB: true });
    this.version(4).stores({
      events: 'id',
      eventSurveys: '++id, eventId',
      categories: 'id',
      eventUserRankings: '++id, eventId',
      eventGuestInformations: '++id, eventId',
    });
  }

  async getEventSurveysByEventId(eventId: number): Promise<EventSurveys | undefined> {
    try {
      const surveys = await this.eventSurveys.where('eventId').equals(eventId).first();
      if (surveys) {
        surveys.surveys = surveys.surveys.map((survey) => {
          const s = Object.assign(new Survey(), survey);
          s.product = survey.product;
          s.surveyCriteriaSets = mapSurveyCriteriaSets(s.surveyCriteriaSets);
          return s;
        });
        surveys.surveysGrouping.forEach((group) => {
          group.forEach((survey) => {
            survey.surveyCriteriaSets = mapSurveyCriteriaSets(survey.surveyCriteriaSets);
          });
        });
      }
      return surveys;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async populate(
    events: Event[],
    eventSurveys: EventSurveys[],
    categories: Category[],
    eventUserRankings: EventUserRanking[],
    eventGuestInformations: EventGuestInformation[],
  ) {
    // Clear and store all local database data
    try {
      await this.clear();
      await this.events.bulkAdd(events);
      await this.eventSurveys.bulkAdd(eventSurveys);
      await this.categories.bulkAdd(categories);
      await this.eventUserRankings.bulkAdd(eventUserRankings);
      await this.eventGuestInformations.bulkAdd(eventGuestInformations);
    } catch (err) {
      console.error(`Error populating db: ${err}`);
    }
  }

  async clear() {
    try {
      await this.events.clear();
      await this.eventSurveys.clear();
      await this.categories.clear();
      await this.eventUserRankings.clear();
      await this.eventGuestInformations.clear();
      await this.storageService.set(LOCAL_CHANGE, false);
    } catch (err) {
      console.error(`Error clearing db: ${err}`);
    }
  }
}
