import { SurveyCriteriaSet } from './survey-criteriaset.model';
import { Event } from '../events/event.model';
import { Auditable } from '../auditable.model';
import { FeedbackComment } from '../feedback/feedback-comment.model';
import { CommentHolder } from '../feedback/comment-holder.model';
import { Product } from '../products/product.model';

export class Survey implements CommentHolder {
  public id: number;
  public comments: FeedbackComment[];
  public productId: number;
  public productName: string;
  public productIdPlasma: string;
  public productScanId: string;
  public eventId: number;
  public userId: number;
  public surveyCriteriaSets: SurveyCriteriaSet[];
  public blindTestTitle: string;
  public surveyStatus: UserSurveyStatus;
  public period: number;
  public auditable: Auditable;
  public positivePoints: string;
  public improvementPoints: string;
  public initial: boolean;
  public order: number;
  public stage: string;
  public version: number;
  public isDurabilitySurveyDisabled: boolean; // front variable only
  public localUpdated: boolean; // mobile only

  private _product: Product;

  get product(): Product {
    return this._product;
  }

  set product(product: Product) {
    if (product && product.id) {
      this._product = product;
      this.productId = product.id;
      // this.productName = product.title || product.name;
      this.productIdPlasma = product.idPlasma;
    }
  }

  private _event: Event;

  get event(): Event {
    return this._event;
  }

  set event(event: Event) {
    this._event = event;
    if (event.id) this.eventId = event.id;
  }

  /**
   *
   * @param survey other survey
   * @return Return true is this survey has a status before the survey in parameter
   */
  isOtherSurveyHasNextStatus(survey: Survey): boolean {
    // Dans tous les cas, on retourne true si le survey remote n'est plus init
    // et que la date de dernière modification du survey remote est plus récente que cette du mobile
    return (
      survey.surveyStatus != UserSurveyStatus.INIT &&
      new Date(survey.auditable.lastModifiedDate) > new Date(this.auditable.lastModifiedDate)
    );
  }

  getComments(): FeedbackComment[] {
    return this.comments;
  }

  getHolderId(): number {
    return this.id;
  }
}

export enum UserSurveyStatus {
  INIT = 'INIT',
  COMPLETED = 'COMPLETED',
  FINALISED = 'FINALISED',
}

export class SurveyMethods {
  static getProductDisplay(survey: Survey) {
    const stageVersion = (survey.stage ? survey.stage : '') + (survey.version ? survey.version : '');
    return !survey.blindTestTitle
      ? survey.productName + (stageVersion != '' ? ' ' + stageVersion : '')
      : survey.blindTestTitle;
  }
}
